.App {
  font-family: sans-serif;
  text-align: center;
}

@font-face {
  font-family: "Gotham";
  src: url("/Gotham-Medium.woff2") format("woff2"),
    url("/Gotham-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("/Gotham-Bold.woff2") format("woff2"),
    url("/Gotham-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
